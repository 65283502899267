.App{
  position:relative;
  min-height: 100%;
  height:100vh;
}
.App-container{
  margin:auto;
  position:relative;
  width:300px;
  min-height: 610px;
}
.App-container img{
  cursor: pointer;
}
.App-logo{
  margin:0 95px;
  width:110px;
}
.App-link {
  color: #61dafb;
}
.suggestionsContainer{
  width:100%;
  position: relative;
  min-height:388px;
  background-color:#f7941d;
}
.mainLink{
  display:flex;
  justify-content: center;
  line-height: 56px;
  cursor:pointer;
  padding: 10px 0px;
}
.artivelab{
  background-color:#f7941d;
  position: relative;
  bottom: 0;
  width: 100%;
  text-align: center;
  box-sizing: border-box;
  padding: 5px;
}

.artivelab a{
  color:#222;
  text-decoration:none;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media screen and (max-width:394px){
  .mainLink{
    display:block;
    text-align: center;
  }
}