
.trackForm input{
	width:100%;
	padding:15px 5px;
	border-radius: 60rem;
}
.trackForm button{
   background-color: #f7941d;
   border: 0;
   border-radius: 60rem;
   color: #fff;
   padding: 1rem;
   position: absolute;
   right: -10px;
   top: 3px;
   width: 42px;
   height: 42px;
   display: flex;
   justify-content: center;
   align-items: center;
   cursor: pointer;
}
.trackingNumber{
   position:relative;
}
.statusTable{
   display:flex;
}
.statusIcon{
   display:inline-block;
   border-radius: 60rem;
   width:3em;
   height:3em;
   color:white;
   position:relative;

}
.statusIcon svg{
   width:1em;
   height:1em;
   margin-top:16px;
   margin-left: 16px;
}

.statusTable{
   display: flex;
   flex-direction: column;
   justify-content: space-evenly;
   border-spacing:3em;
   margin-top:15px;
}
.statusRow{
   display:flex;
   min-height:105px;
}

.iconContainer{
   position: relative;
}
.iconLine{
   background-color: #f90;
   width: 1px;
   height: 105px;
   position: absolute;
   left: 50%;
   z-index: -1;
}
.inactive .iconLine{
   opacity:0.5;
   background-color: rgb(110, 110, 110);
   width: 1px;
   height: 105px;
   position: absolute;
   left: 50%;
   z-index: -1;

}
.titlesContainer{
   margin-left:6px;
}
.statusTitle{
   white-space: nowrap;
   margin:0;
}
.statusMessage{
   font-size: 0.8rem;
   margin-left: 0.5rem;
   min-height: 1rem;
   white-space: nowrap;
}
.inactive{
   opacity:0.5;
}

