.carouselContainer {
  width: 100%;
  margin:0 auto;
}
.headline{
  padding:5px 0;
  text-align: center;
  color:#fff;
}
.headline h3{
  margin:0;
}
.carousel {
  background-color:#f7941d;
  padding:10px 20px;
}

.carouselItem{
  cursor:pointer;
  background-color:#fff;
  display: inline-block;
  width:200px;
  height:280px;
  padding:7px;
}
.carouselItem:last-child{
  margin-right:0;
}
.carouselItem p{
  margin:5px;
  font-size:11px;
  text-align:left;
}
.manufacturerName{
  min-height:22.25px;
}
.carouselInfo,.manufacturerGrid{
  display:grid;
  grid-template-columns: 70% 30%;
}
.cartIcon svg{
  color:white;
  background-color:#ddd;
  padding:8px 8px;
  border-radius:60px;
  font-size:14px;
}

.carouselPrice{
  position:relative;
  bottom:-20px;
}
.carouselPrice p{
  text-align: right;
  color:#f7941d;
}
.carouselPrice s{
  color:#A9A9A9;
}

.productName{
/*  color:#f7941d;*/
  text-align:center;
  min-height:30px;
}
p.percentage{
  display:flex;
  justify-content: flex-end;
  margin:0;

}
.percentage span{
  background-color:#666;
  color:#fff;
  font-size:14px;
  font-weight:400;
  padding:2px 5px;
}
